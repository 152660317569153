import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Winter Term 2024 Menu",
  "date": "2024-02-09",
  "author": "Andy B",
  "featuredImage": "spacepi.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This weekend at CoderDojo we launched our first project "Menu".  This is a curated list of links to coding projects, ranging from beginner to advance.  If you're in need of some ideas for what to work on at CoderDojo (or at home!) then take a look.`}</p>
    <p>{`You can find the menu online `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1DY57xWJ65U0tvmhJlg-J_U0ctPyJ0mTasCq174Xo3VM/edit?usp=sharing"
      }}>{`here`}</a>{`, and we'll have printed copies at each of the sessions from now on.`}</p>
    <p>{`If you have any suggestions or requests for projects in include on the Summer Term Menu, please let us know!`}</p>
    <p>{`The remaining dates for this term are:`}</p>
    <ul>
      <li parentName="ul">{`24th February`}</li>
      <li parentName="ul">{`9th March`}</li>
      <li parentName="ul">{`23rd March`}</li>
    </ul>
    <p>{`You can book you free space now in the `}<a parentName="p" {...{
        "href": "https://zen.coderdojo.com/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library"
      }}>{`usual place`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      